nav {
  font-family: Helvetica, sans-serif;
  font-weight: 700;
}

nav img {
  height: 30px;
  margin: 0.5rem 0;
}

nav li a {
  display: block;
}
