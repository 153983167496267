:root {
  --primary: linear-gradient(164.83deg, #43ba94 25.75%, #006881 93.9%);
  --secondary: linear-gradient(
    180deg,
    rgba(252, 227, 138, 0.62) 0%,
    #f38181 97.92%
  );
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* override input date styles */
input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("./assets/icons/date.svg") no-repeat center;
  width: 30px;
  height: 30px;
}

/* override input dropdown styles */
.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: url("./assets/icons/dropdown.svg");
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  height: 30px;
  width: 30px;
  pointer-events: none;
}

/* override input file styles */
input[type="file"]::-webkit-file-upload-button {
  display: none;
}

/* override accordion styles */
.accordion {
  font-family: "Avenir", sans-serif;
}

.accordion .accordion-item {
  border: none;
}

.accordion .accordion-button {
  font-weight: 700;
}

/* override tooltip styles */
.tooltip-inner {
  max-width: 500px !important;
}
