/* override table styles */
.table,
.table thead {
  vertical-align: middle;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.table tbody {
  color: #858585;
}

/* override pagination styles */
.page-item.active .page-link {
  background: var(--primary);
}
